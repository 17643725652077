<template>
    <div>
        <v-card>
            <v-tabs v-model="tab" light>
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#addUser">
                    Benutzer einladen

                </v-tab>
                <v-tab href="#listUserByName">
                    Benutzer nach Name

                </v-tab>
                <v-tab href="#listUserByCompany">
                    Benutzer nach Firma

                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item :key="1" value="addUser">
                    <v-card flat>
                        <v-card-text>
                            <v-col md="9" :key="componentKey">
                                Um einen neuen Anwender hinzuzufügen, einfach eine E-Mail-Adresse
                                eingeben, zugehörige Firma (oder Firmen) angeben und auf "Absenden"
                                <v-form ref="form">
                                    <v-text-field label="E-Mail-Adresse" v-model="email" :rules="emailRules"></v-text-field>
                                    <v-autocomplete v-model="ausgewaehlte_firmen" :items="firmen" outlined dense chips
                                        small-chips label="Firmen" multiple item-text="name" return-object></v-autocomplete>
                                    <v-select label="Berechtigungsstufe" v-model="stufe"
                                        :items="this.$store.state.roles.allRolesArray" item-text="name" :hint="stufe.info"
                                        return-object single-line></v-select>
                                    <v-btn elevation="2" @click="sendInvitation()">Anwender einladen</v-btn>
                                </v-form>
                                <v-snackbar v-model="showSuccess" color="success" :timeout="timeout" :top="top">Einladung
                                    versendet</v-snackbar>
                            </v-col>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text>
                            Folgende Einladungen sind noch ausstehend:

                            <v-data-table :headers="headersListEinladungen"
                                :items="this.$store.state.einladungen.allEinladungenArray" :search="search"
                                :items-per-page="10" class="elevation-1">
                                <template
                                    v-for="header in headersListEinladungen.filter((header) => header.hasOwnProperty('formatter'))"
                                    v-slot:[`item.${header.value}`]="{ value }">
                                    {{ header.formatter(value) }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-icon small @click="deleteUserInvitation(item)">
                                        mdi-delete
                                    </v-icon>
                                </template>
                                <template v-slot:top>
                                    <v-dialog v-model="dialogDeleteInvitation" max-width="800px">
                                        <v-card>
                                            <v-card-title class="text-h5">Einladungen an &quot;{{ editedItem.email }} {{
                                                editedItem.family_name }}&quot; wirklich löschen?</v-card-title>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="closeDeleteInvitation">abbrechen</v-btn>
                                                <v-btn color="blue darken-1" text
                                                    @click="deleteUserInvitationConfirm(editedItem.id)">OK</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </template>

                            </v-data-table>
                        </v-card-text>

                    </v-card>
                </v-tab-item>
                <v-tab-item :key="2" value="listUserByName">
                    <v-card flat>
                        <v-card-title>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Suche" single-line
                                hide-details></v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="headersListByUsername" :items="this.$store.state.users.allUserArray"
                                :search="search" :items-per-page="10" class="elevation-1">
                                <template
                                    v-for="header in headersListByUsername.filter((header) => header.hasOwnProperty('formatter'))"
                                    v-slot:[`item.${header.value}`]="{ value }">
                                    {{ header.formatter(value) }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-icon small class="mr-2" @click="editItem(item)">
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon small @click="deleteItem(item)">
                                        mdi-delete
                                    </v-icon>
                                </template>
                                <template v-slot:top>
                                    <v-dialog v-model="dialog" max-width="800px">
                                        <v-card>
                                            <v-card-title>
                                                <span class="text-h5">{{ editedItem.name }} {{ editedItem.family_name
                                                }}</span>
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-title>Firma hinzufügen</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-autocomplete v-model="ausgewaehlte_firmenUserToCompany"
                                                        :items="firmen" outlined dense chips small-chips label="Firmen"
                                                        multiple item-text="name" return-object></v-autocomplete>
                                                    <v-btn icon color="pink">
                                                        <v-icon x-large
                                                            @click="addUserToFirmen(editedItem.id, ausgewaehlte_firmenUserToCompany)">
                                                            mdi-arrow-right-circle-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </v-row>
                                            </v-card-text>
                                            <v-divider></v-divider>
                                            <v-card-title>Firma entfernen</v-card-title>
                                            <v-card-text>
                                                <v-simple-table>
                                                    <tr>
                                                        <th>Geschaeftspartner-Nr.</th>
                                                        <th>Firma</th>
                                                    </tr>
                                                    <tr v-for="firmennummer in editedItem.firmen" :key="firmennummer">
                                                        <td>{{ firmennummer }}</td>
                                                        <td>{{ getFirmenName(firmennummer) }}</td>
                                                        <td><v-icon v-if="editedItem.firmen.length > 1" small
                                                                @click="deleteFromCompanyConfirm(editedItem.id, firmennummer)">
                                                                mdi-delete
                                                            </v-icon></td>
                                                    </tr>
                                                </v-simple-table>
                                            </v-card-text>
                                            <v-divider></v-divider>
                                            <v-card-title>Userlevel ändern</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-autocomplete v-model="ausgewaehlter_Userlevel"
                                                        :items="$store.state.roles.allRolesArray" outlined dense chips
                                                        small-chips label="Firmen" single item-text="name"
                                                        item-value="level"></v-autocomplete>
                                                    <v-btn icon color="pink">
                                                        <v-icon x-large
                                                            @click="setUserlevel(editedItem.id)">
                                                            mdi-arrow-right-circle-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </v-row>

                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="close">
                                                    Schließen
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                    <v-dialog v-model="dialogDelete" max-width="800px">
                                        <v-card>
                                            <v-card-title class="text-h5">Benutzer &quot;{{ editedItem.name }} {{
                                                editedItem.family_name }}&quot; wirklich löschen?</v-card-title>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="closeDelete">abbrechen</v-btn>
                                                <v-btn color="blue darken-1" text
                                                    @click="deleteItemConfirm(editedItem.id)">OK</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item :key="3" value="listUserByCompany">
                    <v-card flat>
                        <v-card-title>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="searchCompany" append-icon="mdi-magnify" label="Suche" single-line
                                hide-details></v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="headersListByCompany"
                                :items="this.$store.state.companys.allCompaniesArray" :search="searchCompany"
                                :items-per-page="10" class="elevation-1">
                                <template #item.fullAdress="{ item }">{{ item.strasse }} {{ item.hausnummer }}{{
                                    item.hausnummer_zusatz }}, {{ item.plz }} {{ item.ort }}</template>
                                <template v-slot:item.attachedUsers="{ item }">
                                    <v-icon small class="mr-2" @click="editAttachedUsers(item)">
                                        mdi-magnify
                                    </v-icon>
                                </template>
                                <template v-slot:top>
                                    <v-dialog v-model="dialogAttachUser" max-width="800px">
                                        <v-card>
                                            <v-card-title>
                                                <span class="text-h5">{{ editedItem.id }} {{ editedItem.name }}</span>
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-title>Mitarbeiter hinzufügen</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-autocomplete v-model="ausgewaehlte_BenutzerUserToCompany"
                                                        :items="$store.state.users.allUserArray" outlined dense chips
                                                        small-chips label="User" multiple :item-value="item => `${item.id}`"
                                                        :item-text="item => `${item.name} ${item.family_name} (${item.email})`">
                                                    </v-autocomplete>
                                                    <v-btn icon color="pink">
                                                        <v-icon x-large
                                                            @click="addUserToFirmen(ausgewaehlte_BenutzerUserToCompany, [editedItem])">
                                                            mdi-arrow-right-circle-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="closeAttachUser">
                                                    Schließen
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </template>
                            </v-data-table>
                        </v-card-text>

                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>




    </div>
</template>

<script>
import { firestore } from "@/plugins/firebase"
import {
    collection,
    doc,
    getDocs,
    setDoc,
    Timestamp
} from "@firebase/firestore"
import { functions } from "@/plugins/firebase"
import { httpsCallable } from "firebase/functions"
export default {
    data() {
        return {
            search: "",
            searchCompany: "",
            expanded: [],
            tab: "Benutzer hinzufügen",
            headersListByUsername: [
                { text: "Vorname", value: "name" },
                { text: "Nachname", value: "family_name" },
                { text: "Email", value: "email" },
                { text: "Firmen", value: "firmen", formatter: this.concatenateStrings },
                { text: "Level", value: "level", formatter: this.getNameOfStufe },
                { text: "Aktionen", value: "actions", sortable: false },
            ],
            headersListByCompany: [
                { text: "Geschäftspartner-Nr.", value: "id" },
                { text: "Firma", value: "name" },
                { text: "Adresse", value: "fullAdress" },
                { text: "zugeordnete Benutzer", value: "attachedUsers", sortable: false },
            ],
            headersListEinladungen: [
                { text: "Erstellt", value: "created", formatter: this.getDateFromTS },
                { text: "Email", value: "email" },
                { text: "Level", value: "stufe", formatter: this.getNameOfStufe },
                { text: "Firmen", value: "firmen", formatter: this.concatenateStrings },
                { text: "Aktionen", value: "actions", sortable: false },
            ],
            dialog: false,
            dialogDelete: false,
            dialogDeleteInvitation:false,
            dialogDeleteFromCompany: false,
            dialogAttachUser: false,
            editedItem: {},
            componentKey: 0,
            showSuccess: false,
            timeout: 1500,
            top: true,
            email: null,
            emailRules: [
                (v) =>
                    !v ||
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    "E-Mail unvollständig oder inkorrekt"
            ],
            stufe: {
                info: ""
            },
            firmen: [],
            ausgewaehlte_firmen: [],
            ausgewaehlte_firmenUserToCompany: [],
            ausgewaehlte_BenutzerUserToCompany: [],
            ausgewaehlter_Userlevel: null
        }
    },
    computed: {},
    watch: {
        dialogDelete(val) {
            val || this.closeDelete()
        },
        dialogDeleteFromCompany(val) {
            val || this.closeDeleteFromCompany()
        },
        dialogDeleteInvitation(val) {
            val || this.closeDeleteInvitation()
        },
    },
    methods: {
        async setUserlevel(id) {
            const setUserPermissionlevel = httpsCallable(functions, "setUserPermissionlevel")
            const arg={ id: id, level: this.ausgewaehlter_Userlevel }
            console.log("arg",arg)
            const out = await setUserPermissionlevel(arg)
            console.log("out",out)
            if (out.data == "OK") {
                this.ausgewaehlter_Userlevel = ""
                this.dialog = false
            } else {
                this.$store.commit("showSnackbar", {
                    status: "error",
                    message: "Fehler bei der Konfiguration. Folgende Servermeldung empfangen:"+JSON.stringify(out),
                });
            }
        },
        concatenateStrings(firmenarray) {
            let firmennamen = [];
            for (const firma in firmenarray) {
                firmennamen.push(this.getFirmenName(firmenarray[firma]))
            }
            let concatenatedString = firmennamen.join(", ");
            return concatenatedString;
        },
        getDateFromTS(ts) {
            let timestamp;
            switch (typeof (ts)) {
                case "object":
                    timestamp = ts.seconds * 1000;
                    break;
                case "string":
                    timestamp = parseInt(ts)
                    break;
                default:
                    timestamp = ts
                    break;
            }
            const dateobj = new Date(timestamp)
            return dateobj.toLocaleString("de-DE")
        },
        getNameOfStufe(stufe) {
            return this.$store.state.roles.levelToRoles[stufe]
        },
        getAllFirmen: async function () {
            const querySnapshot = await getDocs(collection(firestore, "Firmen"))
            querySnapshot.forEach((doc) => {
                const d = doc.data()
                this.firmen.push({
                    name: d.name,
                    id: doc.id
                })
            })
        },
        getFirmenName(id) {
            const company = this.firmen.find(company => company.id === id);
            return company ? company.name : "Fehler! Unbekannte Geschäftspartner-Nr.";
        },
        sendInvitation: async function () {
            let firmenliste = []
            for (const i of this.ausgewaehlte_firmen) {
                firmenliste.push(i.id)
            }

            const uuidString = (new Date()).getTime().toString(36) + Math.random().toString(36).slice(2)
            await setDoc(doc(firestore, "Einladungen", this.email), {
                created: Timestamp.fromDate(new Date()),
                email: this.email,
                firmen: firmenliste,
                stufe: this.stufe.level,
                uuid: uuidString
            })
            this.showSuccess = true
            this.$refs.form.reset()
        },
        editItem(item) {
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        editAttachedUsers(item) {
            this.editedItem = Object.assign({}, item)
            this.dialogAttachUser = true
        },
        deleteItem(item) {
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteUserInvitation(item) {
            this.editedItem = Object.assign({}, item)
            this.dialogDeleteInvitation = true
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = {}
            })
        },
        closeDeleteFromCompany() {
            this.dialogDeleteFromCompany = false
            this.$nextTick(() => {
                this.editedItem = {}
            })
        },
        closeDeleteInvitation() {
            this.dialogDeleteInvitation = false
            this.$nextTick(() => {
                this.editedItem = {}
            })
        },
        closeAttachUser() {
            this.dialogAttachUser = false
        },
        deleteUserInvitationConfirm(id) {
            this.$store
                .dispatch("deleteInvitation", { id: id })
                .then(() => {
                    console.log(`deleteInvitation für ID ${id} abgeschlossen`)
                })
            this.closeDeleteInvitation()
        },
        deleteItemConfirm(id) {
            this.$store
                .dispatch("deleteUser", { id: id })
                .then(() => {
                    console.log(`deleteUser für ID ${id} abgeschlossen`)
                })
            this.closeDelete()
        },
        deleteFromCompanyConfirm(id, companyid) {
            this.$store
                .dispatch("deleteUserFromCompany", { id: id, companyid: companyid })
                .then(() => {
                    console.log(`deleteUserFromCompany für ID ${id} und Company ${companyid} abgeschlossen`)
                })
            this.close()
        },
        save() {
            /*if (this.editedIndex > -1) {
                Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
                this.desserts.push(this.editedItem)
            }*/
            this.close()
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = {}
            })
        },
        addUserToFirmen(id, firmen) {
            let firmenliste = [];
            for (const firma of firmen) {
                firmenliste.push(firma.id)
            }
            let ids = id
            if (!Array.isArray(id)) {
                ids = [id]
            }
            this.$store
                .dispatch("addUserToFirmen", { ids: ids, companyids: firmenliste })
                .then(() => {
                    this.ausgewaehlte_firmenUserToCompany = []
                    console.log(`addUserToFirmen für ID ${id} abgeschlossen`)
                })
            this.editedItem = {}
            this.close()
            this.closeDeleteFromCompany()
            this.closeAttachUser()
        }
    },
    async mounted() {
        // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }
        await this.getAllFirmen()
        this.$store
            .dispatch("getAllUsers")
            .then(() => {
                //console.log("getAllUsers abgeschlossen")

            })
        this.$store
            .dispatch("getAllCompanies")
            .then(() => {
                //console.log("getAllCompanies abgeschlossen")

            })
        this.$store
            .dispatch("getAllEinladungen")
            .then(() => {
                //console.log("getAllEinladungen abgeschlossen")

            })
        this.$store
            .dispatch("getAllRoles")
            .then(() => {
                console.log("getAllRoles abgeschlossen")
            })
    }
}
</script>

<style></style>